import React, { useContext, useState } from 'react';

import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Box, Container, Grid, Paper } from '@material-ui/core';
import {
  ContactMailTwoTone,
  CloudUploadTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  NavigateBefore,
  PeopleAlt,
  PersonAddDisabledTwoTone,
} from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { PageContent } from '../Common/styled/PageContent';
import { Margin } from '../Common/styled/Margins';
import { isEmpty } from '../../utils/ObjectUtils';
import { SectionBar } from '../../componentsUI/SectionBar';
import { EditPatientDialog } from './modal/PatientEditDialog';
import { DeletePatientDialog } from './modal/DeletePatientDialog';
import { InvitePatientDialog } from './modal/InvitePatientDialog';
import { UnlinkPatientDialog } from './modal/UnlinkPatientDialog';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import PatientMainProfileInfo from './PatientMainProfileInfo';
import PatientSecondaryInfo from './PatientSecondaryInfo';
import PatientDicomStudies from './archives/PatientDicomStudies';
import PatientMedicalCases from './archives/PatientMedicalCases';
import PatientFiles from './archives/PatientFiles';
import LastVitalSigns from './archives/LastVitalSigns';
import LaboratoryResults from './archives/LaboratoryResults';
import MedicalRecord from './archives/MedicalRecord';
import Explorations from './archives/Explorations';
import Instructions from './archives/Instructions';
import { UploadFileDialog } from '../File/UploadFileDialog';
import { Navbar } from '../Navbar/styled/NavbarStyles';
import { ArticleContainerUI } from '../Common/styled/ArticleContainerUI';
import { DeleteFileDialog } from '../File/DeleteFileDialog';
import { setHospitalEnvironment } from '../../utils/HospitalUtils';

const useStyles = makeStyles((theme) => ({
  box: {
    width: '100%',
    flexGrow: 1,
    padding: '1.25em 0',
    '& .MuiGrid-item': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  accordion: {
    margin: '-.5em 0',
    width: '100%',
    '& .MuiAccordionSummary-root': {
      color: theme.palette.common.black,
      background: theme.palette.primary.light,
    },
  },
}));

export default ({ patient, refetch }) => {
  const { t } = useTranslation();
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [deleteFileModal, setDeleteFileModal] = useState(false);
  const [unlinkUserModal, setUnlinkUserModal] = useState(false);

  const classes = useStyles();
  const context = useContext(ThemeContext);
  const dispatch = useDispatch();
  const hospital = useSelector((state) => state.hospital);

  const dicomStudies = patient.medicalCases
    .map((medicalCase) => medicalCase.studies
      .map((study) => ({
        ...study,
        medicalCase: {
          uuid: medicalCase.uuid,
          name: medicalCase.title,
        },
      }))).flat(1);

  if (hospital && hospital.uuid !== patient.hospital.uuid) {
    setHospitalEnvironment({ uuid: patient.hospital.uuid, dispatch, context });
  }

  const openDelete = () => setDeleteModal(true);
  const handleCloseDelete = (value) => {
    setDeleteModal(false);
    if (value) navigate('/patients');
  };

  const handleCloseDeleteFile = () => setDeleteFileModal(false);

  const openInvite = () => setInviteModal(true);
  const handleCloseInvite = () => refetch().then(setInviteModal(false));

  const openUnlink = () => setUnlinkUserModal(true);
  const handleCloseUnlink = () => refetch().then(setUnlinkUserModal(false));

  const openEdit = () => setEditModal(true);
  const handleCloseEdit = () => refetch().then(setEditModal(false));

  const openUploadFile = () => setUploadFileModal(true);
  const handleCloseUpload = () => refetch().then(setUploadFileModal(false));

  const deleteFileRequest = (index) => {
    setSelectedFile(patient.patientFiles[index]);
    setDeleteFileModal(true);
  };

  const handleBack = () => {
    navigate('/patients');
  };

  const uploadFileButtons = (hospital && hospital.menuOptionFiles) ? [
    { name: 'divider2', type: 'divider' },
    { name: 'upload.file', icon: CloudUploadTwoTone, I: 'edit', data: patient, handleClick: openUploadFile, disabled: false },
  ] : [];

  const unlinkPatientButton = !!patient.userUuid ? [
    { name: 'unlink.registered.user', icon: PersonAddDisabledTwoTone, I: 'edit', data: patient, handleClick: openUnlink },
  ] : [];

  const buttons = [
    { name: 'go.back', icon: NavigateBefore, handleClick: handleBack },
    { name: 'divider2', type: 'divider' },
    ...unlinkPatientButton,
    { name: 'invite.patient', icon: ContactMailTwoTone, I: 'edit', data: patient, handleClick: openInvite, disabled: !!patient.userUuid },
    ...uploadFileButtons,
    { name: 'divider2', type: 'divider' },
    { name: 'edit.patient', icon: EditTwoTone, I: 'edit', data: patient, handleClick: openEdit, disabled: false },
    { name: 'delete.patient', icon: DeleteTwoTone, I: 'delete', data: patient, handleClick: openDelete, disabled: false },
  ];

  const backButton = {
    label: t('back.to.patients'),
    icon: PeopleAlt,
    onClick: handleBack,
  };

  const getApplicationPath = (patientData) => {
    if (isEmpty(patientData)) return null;
    return {
      patient: {
        name: `${patientData.name} ${patientData.surname}`,
        link: `/patient/${patientData.uuid}`,
      },
    };
  };

  const ArchivesWrapper = ({ children }) => (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={10} xl={7}>
        <Paper elevation={2}>
          <Box className={classes.box}>
            <Grid container spacing={1}>
              <Grid item className={classes.accordion}>
                {children}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );

  const explorations = [...patient.explorations].sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

  return (
    <ArticleContainerUI>
      <Navbar>
        <EditPatientDialog open={editModal} onClose={handleCloseEdit} patientId={patient && patient.uuid} />
        <DeletePatientDialog open={deleteModal} onClose={handleCloseDelete} patient={patient} />
        <InvitePatientDialog open={inviteModal} onClose={handleCloseInvite} patient={patient} />
        <UnlinkPatientDialog open={unlinkUserModal} onClose={handleCloseUnlink} patient={patient} />
        <UploadFileDialog open={uploadFileModal} onClose={handleCloseUpload} uuid={patient.uuid} type="patientFile" />
        {!!selectedFile && (
          <DeleteFileDialog
            open={deleteFileModal}
            onClose={handleCloseDeleteFile}
            file={selectedFile}
            uuid={patient && patient.uuid}
            type="patientFile"
          />
        )}
        <SectionBar title="patient.info" items={buttons} back={backButton} breadcrumbs={getApplicationPath(patient)} />
      </Navbar>
      <Container maxWidth="lg" className="article">
        <ScrollableContainer padding="1.25em 0 0">
          <PageContent>
            <Grid container spacing={1}>
              <PatientMainProfileInfo patient={patient} action={openInvite} />
              <PatientSecondaryInfo patient={patient} />
            </Grid>
            <LastVitalSigns explorations={explorations} dob={patient.birthDate} />
            <ArchivesWrapper>
              <PatientMedicalCases medicalCases={patient.medicalCases} />
              {/* <MedicalRecord record={null} /> */}
              {/* <Explorations values={explorations} /> */}
              <PatientDicomStudies dicomStudies={dicomStudies} />
              <PatientFiles files={patient.patientFiles} deleteRequest={deleteFileRequest} />
              {/* <LaboratoryResults results={[]} /> */}
              {/* <Instructions values={[]} /> */}
            </ArchivesWrapper>
            <Margin />
          </PageContent>
        </ScrollableContainer>
      </Container>
    </ArticleContainerUI>
  );
};
