import React, { useState, forwardRef } from 'react';

import { useTranslation } from 'react-i18next';
import { alpha, makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Slide } from '@material-ui/core';

import { parseGraphqlErrors } from '../../../utils/FormikUtils';
import { networkErrorParse } from '../../../utils/ErrorGraphQLUtils';
import Loading from '../../Common/Loading';
import { UNLINK_PATIENT } from '../../../graphql/mutations';
import { AlertUI } from '../../../componentsUI/Alert';
import {InnerDivHTML} from "../../Common/InnerHTML";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  warning: {
    marginTop: '.75rem',
    fontSize: '1.375rem',
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

export const UnlinkPatientDialog = ({ open, onClose, patient = {} }) => {
  const { t } = useTranslation();
  const [serverError, setServerError] = useState(false);
  const [unlinkError, setUnlinkError] = useState(false);
  const [unlinkCalled, setUnlinkCalled] = useState(false);
  const classes = useStyles();

  const closeForm = () => {
    setServerError(false);
    setUnlinkError(false);
    setUnlinkCalled(false);
    onClose();
  };

  const [unlinkPatient, { loading, called }] = useMutation(UNLINK_PATIENT, {
    onCompleted() {
      toast(t('user.has.been.unlinked.successfully'), { className: 'toast-success' });
      closeForm();
    },
    onError(e) {
      const errorMessage = networkErrorParse(e);
      setUnlinkCalled(called);
      setUnlinkError(errorMessage);
      setServerError(errorMessage === 'you.are.not.allowed.to.perform.this.action'
        ? 'permission.error'
        : 'server.error');
    },
  });

  const handleSubmit = async ({ setErrors }) => {
    setServerError(false);
    setUnlinkError(false);
    try {
      await unlinkPatient({
        variables: {
          patientUuid: patient.uuid,
        },
      });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={closeForm}
    >
      <DialogTitle>{t('unlink.registered.user')}</DialogTitle>
      <DialogContent>
        {unlinkError
          ? <AlertUI severity="error" title={t('error')}>{t(unlinkError)}</AlertUI>
          : <AlertUI severity="info" title={t('info')}>{t('unlink.warning')}</AlertUI>}
        <InnerDivHTML
          classes={classes.warning}
          content={t('unlink.confirmation', { user: patient.email })}
        />
        {loading && <Loading />}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={closeForm}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          disabled={(loading || unlinkCalled) && !serverError}
          color="primary"
          onClick={handleSubmit}
        >
          {t('unlink')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
